<script>
import feather from 'feather-icons';
import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects';

export default {
	components: { ProjectSingle },
	data: () => {
		return {
			projects,
			projectsHeading: 'Wat verwacht jij?',
			selectedCategory: '',
			searchProject: '',
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>


<template>


	<!-- Projects grid -->
	<section class="pt-10 sm:pt-14">
		<!-- Projects grid title -->
		<div  data-aos="fade-down" class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				{{ projectsHeading }}
			</p>
		</div><br><br><br><br><br>

	

		<!-- Projects grid -->
		<div  data-aos="fade-down"
			class="flex space-x-4 flex-cols-1 sm:flex-cols-2 lg:flex-cols-3 mt-6 sm:gap-10"
		>
			<ProjectSingle
				v-for="project in filteredProjects"
				:key="project.id"
				:project="project"
			/>
		</div>
	</section><br><br><br><br>



	<!-- This example requires Tailwind CSS v2.0+ -->
<div  data-aos="fade-in" class=" text-ternary-dark dark:text-ternary-light">
  <div class="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
    <div class="space-y-12">
      <div  data-aos="fade-in" class="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
        <h2 class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">Onze Reviews</h2><br><br><br>
     
      </div>
      <ul role="list" class="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
        <li>
          <div class="space-y-6">
            <img class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src="@/assets/images/Joaquin.jpeg" alt="">
            <div class="space-y-2">
              <div class="text-lg leading-6 font-medium space-y-1">
                <h3>Joaquin</h3>
                <p class="text-indigo-600">Leerling</p>
				<h4>Anas heeft mij in zijn lessen verschillende nieuwe technieken aangeleerd, en motiveerde mij om mezelf in de kunst van gitaarspelen te blijven overtreffen, door aanmoediging om zelf liedjes te leren en ook na mijn lestijd bij hem ben ik doorgegaan met de verbetering van mijn gitaarspelen.</h4>
              </div>
         
            </div>
          </div>
        </li>
		<li>
          <div class="space-y-6">
				<img class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src="@/assets/images/Puck.jpeg" alt="">            <div class="space-y-2">
              <div class="text-lg leading-6 font-medium space-y-1">
                <h3>Puck</h3>
                <p class="text-indigo-600">Leerling</p>
				<h4>Anas is een goede leraar die goed en geduldig kan uitleggen en leuke liedjes kiest om te leren. De liedjes hebben een goeie opbouw van makkelijk naar wat meer lastiger, maar niks wat je nog niet aan kan. Ik ben tevreden over de lessen.</h4>
              </div>
           
            </div>
          </div>
        </li>
		<li>
          <div class="space-y-6">
				<img class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56" src="@/assets/images/Bram.jpeg" alt="">            <div class="space-y-2">
              <div class="text-lg leading-6 font-medium space-y-1">
                <h3>Bram</h3>
				<p class="text-indigo-600">Leerling</p>
                <h4>Anas is een goede gitaarleraar voor mijn kind. Hij weet hem altijd weer te motiveren met leuke liedjes en oefeningen. Het is niet alleen maar leuk, maar er wordt ook serieus gewerkt om de techniek goed onder knie te krijgen. Kortom een fijne gitaarleraar met een professionele aanpak!</h4>
              </div>
        
            </div>
          </div>
        </li>
        <!-- More people... -->
      </ul>
    </div>
  </div>
</div>
<br><br><br><br><br>
        <h2  data-aos="fade-up" class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">Onze Locatie</h2><br><br><br>

<div  data-aos="fade-up" style="width: 100% padding:10px;webkit-border-radius: 20px;-moz-border-radius: 20px;border-radius: 20px;width:100%;margin:0 auto;overflow:hidden;"><iframe width="100%" height="400" frameborder="0" scrolling="yes" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Oostzijde%20381+(Guitarly)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/sport-gps/">hiking gps</a></iframe></div>
</template>

<style scoped></style>
