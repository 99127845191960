<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
		<!-- Banner left contents -->
		<div data-aos="fade-right" class="w-full md:w-1/3 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-7xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
			>
				Guitarly
			</h1>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-gray-400"
			>
				is dé nieuwe gitaarschool in het hart van de Zaanstreek
			</p>
	
		</div><br><br><br><br><br>

		<!-- Banner right illustration -->
		<div data-aos="fade-left" class="w-full md:w-1/3 text-right float-right">
			<img
				v-if="theme === 'light'"
				src="@/assets/images/test20.png"
				alt="Developer"
			/>
			<img
				v-else
				src="@/assets/images/test20.png"
				alt="Developer"
			/>
		</div>
	</section><br><br><br><br><br><br><br><br>
</template>

<style scoped></style>
