<script>
import feather from 'feather-icons';


export default {
	
	data() {
		return {
			socials: [
			
		
				{
					id: 1,
					name: 'Facebook',
					icon: 'facebook',
					url: 'https://www.facebook.com/Guitarly.nl',
				},
				{
					id: 2,
					name: 'Instagram',
					icon: 'instagram',
					url: 'https://www.instagram.com/guitarly.nl/',
				},
			],
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>
<template>


<!-- Footer social links -->


			<div class="flex flex-col justify-center items-center mb-12 sm:mb-20">
				<p class="font-general-semibold text-3xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-5">
					Volg ons
				</p>
				<ul class="flex gap-4 sm:gap-8">
					<a
						v-for="social in socials"
						:key="social.id"
						:href="social.url"
						target="__blank"
						class="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-500"
					>
						<i
							:data-feather="social.icon"
							class="w-5 sm:w-8 h-5 sm:h-8"
						></i>
					</a>
				</ul><br>

                <div data-aos="fade-left" class="flex flex-col items-center pt-8 pb-12 md:flex-row-reverse md:justify-between md:pt-6">
                    <form action="https://guitarly.us9.list-manage.com/subscribe/post?u=8b76a009c56808b3d90c82c90&amp;id=ec4e85d0a1&amp;f_id=003701e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate flex w-full justify-center md:w-auto" target="_blank" novalidate>
                    <div class='w-60 min-w-0 shrink'>
                        <input type="email" value="" name="EMAIL" id="mce-EMAIL" aria-label="Email address" placeholder="Uw Email" autocomplete="email" class="required email block w-full appearance-none rounded-lg border border-gray-200 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm" required>
                    </div>

                    <button class="inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors relative overflow-hidden bg-guitarly-blue hover:bg-guitarly-light-blue ml-4 flex-none" type="submit" value="Subscribe" name="subscribe">
                        <span class="hidden lg:inline text-ternary-light">Subscribe</span>
                        <span class="lg:hidden text-ternary-light">Subscribe</span>
                    </button>
                    </form>
                </div>

           
			</div>

                
<!-- Begin Mailchimp Signup Form -->






<!--End mc_embed_signup-->


</template>

<style scoped>

</style>
