<script>


export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'Guitarly',
			author: 'Amjad Alarori',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center">
		<div
			class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"
		>
			&copy; {{ copyrightDate }}.
		<div class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"><p class="font-general-medium hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 duration-500"> Guitarly </p></div>
			
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
