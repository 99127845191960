// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
	
		category: 'De lessen zijn op maat. We bepalen samen wat je niveau is en wat je wilt leren. We geven les in alle stijlen, van klassiek tot elektrisch.',
		img: require('@/assets/images/5.jpeg'),
	},
	{
		id: 2,
		
		category: 'Er worden geen saaie lesmethodes gebruikt. We oefenen muziek die jij leuk vindt waar de technieken in zitten die je wilt leren.',
		img: require('@/assets/images/7.jpeg'),
	},

];

export default projects;
