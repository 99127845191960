<script>
import feather from 'feather-icons';
import FooterCopyright from './FooterCopyright.vue';
import Mailchimp from './Mailchimp.vue';

export default {
	components: { FooterCopyright, Mailchimp },
	data() {
		return {
			socials: [
			
		
				{
					id: 1,
					name: 'Facebook',
					icon: 'facebook',
					url: 'https://stoman.medium.com',
				},
				{
					id: 2,
					name: 'Instagram',
					icon: 'instagram',
					url: 'https://www.instagram.com/guitarly.nl/',
				},
			],
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="container mx-auto ">
		<div
			class="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark"
		>
			



<Mailchimp />
			<!-- Footer copyright -->
			<FooterCopyright />

			
		</div>
	</div>
</template>

<style scoped></style>
